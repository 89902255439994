import { MdOutlineFormatBold, MdOutlineFormatItalic } from "react-icons/md";
import { ImUnderline } from "react-icons/im";
import { FaChevronDown, FaCircle } from "react-icons/fa";
import { LiaLinkSolid, LiaUnlinkSolid } from "react-icons/lia";


import { useDBStore } from "../stores/dbStore";
import { PiTextAaBold } from "react-icons/pi";
import { FaChevronRight } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { Menus, colors } from "../constants";

const InlineToolbar = () => {
  const { inlineToolCords, showInlineTool, updateNode, replaceNode, currentPage, pages } =
    useDBStore((state) => state);
  const { activeNode, nodes } = pages[currentPage];
  const node = nodes[activeNode];
  const $this = useRef(null);
  const [open, setOpen] = useState(null);

  useEffect(() => {
    if (showInlineTool && $this.current) {
      const selection = window.getSelection();
      const range = selection?.rangeCount > 0 ? selection?.getRangeAt(0) : null;
      if (!range) return;
      const focusNode = range.startContainer.parentNode;
      const anchorNode = range.endContainer.parentNode;
      const boldBtn = $this.current.querySelector(".btn-bold");
      const italicBtn = $this.current.querySelector(".btn-italic");
      const underlineBtn = $this.current.querySelector(".btn-underline");
      const inBold = anchorNode?.closest("b") && focusNode?.closest("b");
      const inItalic = anchorNode?.closest("i") && focusNode?.closest("i");
      const inUnderline = anchorNode?.closest("u") && focusNode?.closest("u");

      inBold
        ? boldBtn?.classList.add("active")
        : boldBtn?.classList.remove("active");
      inItalic
        ? italicBtn?.classList.add("active")
        : italicBtn?.classList.remove("active");
      inUnderline
        ? underlineBtn?.classList.add("active")
        : underlineBtn?.classList.remove("active");
    }
  });

  return (
    <div
      ref={$this}
      style={{
        display: showInlineTool ? "block" : "none",
        top: inlineToolCords.top + 30,
        left: inlineToolCords.left,
      }}
      onMouseLeave={() => setOpen(null)}
      className="bg-white inline-tool border absolute z-20 w-[fit-content] flex flex-col *:select-none rounded-md"
    >
      <div className="flex not-selectable justify-center items-center px-1 *:size-[30px] *:rounded-sm  *:mx-0.5 *:my-auto *:flex *:justify-center *:items-center *:font-bold">
        <button
          className="group flex hover:bg-slate-300"
          onMouseOver={() => setOpen("turn-to")}
          onMouseOut={() => setOpen(null)}
        >
          <PiTextAaBold size={24} />
          <FaChevronRight
            style={{ display: open === "turn-to" ? "none" : "inline-block" }}
            size={10}
            className="block group-focus:hidden ml-0.5"
          />
          <FaChevronDown
            style={{ display: open === "turn-to" ? "inline-block" : "none" }}
            size={10}
            className="group-focus:block ml-0.5"
          />
        </button>
        <button
          onMouseOver={() => setOpen(null)}
          onClick={() => document.execCommand("bold", false)}
          className="btn-bold hover:bg-slate-300"
        >
          <MdOutlineFormatBold size={24} />
        </button>
        <button
          onMouseOver={() => setOpen(null)}
          onClick={() => document.execCommand("italic", false)}
          className="btn-italic hover:bg-slate-300"
        >
          <MdOutlineFormatItalic size={24} />
        </button>
        <button
          onMouseOver={() => setOpen(null)}
          onClick={() => document.execCommand("underline", false)}
          className="btn-underline *:mb-0.5 hover:bg-slate-300"
        >
          <ImUnderline size={18} />
        </button>
        <button
          className="btn-link hover:bg-slate-300"
          onClick={() => setOpen(open === "link" ? null : "link")}
          onMouseOut={() => setOpen(null)}
        >
          <LiaLinkSolid size={22} />
        </button>
        <button
          onMouseOver={() => setOpen(null)}
          className="btn-color *:mb-0.5 hover:bg-slate-300"
        >
          <FaCircle size={18} />
        </button>
        <button
          onMouseOver={() => setOpen("color")}
          className="*:mb-0.5 hover:bg-slate-300"
        >
          <FaCircle fill="red" size={18} />
        </button>
      </div>
      <div className="w-full h-auto text-gray-500">
        <div
          onMouseOver={() => setOpen("turn-to")}
          style={{ display: open === "turn-to" ? "flex" : "none" }}
          className="border w-[150px] bg-white py-2 -mt-0.5 h-[fit-content] flex-col"
        >
          {Menus.map(({ name, icon, type, element }, i) => {
            return (
              <span
                key={i}
                onClick={() => replaceNode(activeNode, type, { element })}
                className="flex justify-start hover:bg-gray-200/90 px-2 py-1"
              >
                <span>{icon}</span> <small className="ml-4">{name}</small>
              </span>
            );
          })}
        </div>
        <div
          onMouseEnter={() => setOpen("link")}
          style={{ display: open === "link" ? "block" : "none" }}
          className="w-full h-[fit-content] border bg-gray-400 -mt-1"
        >
          {open === "link" && (
            <input
              className="rounded-sm px-2 py-1 m-auto w-full outline-none"
              type="text"
              autoFocus={true}
              placeholder="https://"
            />
          )}
        </div>

        <div
          // onMouseOver={() => setOpen("color")}
          onClick={() => (open === "color" ? setOpen(null) : setOpen("color"))}
          style={{ display: open === "color" ? "flex" : "none" }}
          className="border relative left-1/2 w-[150px] bg-white py-2 h-[fit-content] flex-col"
        >
          {colors.map(({ name, color, type }, i) => {
            return type === "title" || type === "delimiter" ? (
              <>
                {type === "delimiter" ? (
                  <hr key={i} className="pointer-events-none mx-2" />
                ) : (
                  <small key={i}
                    className="mx-2 w-full pointer-events-none"
                  >
                    {name}
                  </small>
                )}
              </>
            ) : (
              <span
                key={i}
                className="flex justify-start hover:bg-gray-200/90 px-2 py-1"
              >
                <span
                  style={
                    type === "background"
                      ? { backgroundColor: color, color: "black" }
                      : { color }
                  }
                  className="rounded-full h-6 pb-2.5 pl-1.5 w-7"
                  onClick={() => {
                    const styles =
                      type === "background"
                        ? { ...node?.styles, backgroundColor: color }
                        : { ...node?.styles, color };
                    updateNode(activeNode, { styles });
                  }}
                >
                  A
                </span>
                <small
                  className="ml-4"
                  onClick={() => {
                    const styles =
                      type === "background"
                        ? { ...node?.styles, backgroundColor: color }
                        : { ...node?.styles, color };
                    updateNode(activeNode, { styles });
                  }}
                >{name}</small>
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default InlineToolbar;
