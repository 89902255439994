import React, { useEffect, useRef, useState } from "react";
import { useDBStore } from "../../stores/dbStore";
import { Selection, rangy } from "../../DOM";
import { getAbsolutePosition } from "../../helpers";
// import { sanitize } from "../../helpers";

const ContentEditable = ({ id, ...otherProps }) => {
  const inputRef = useRef(null);
  const [html, setHtml] = useState(null);
  // const [typing, setTyping] = useState(null);
  const [selection, setSelection] = useState(null);
  const [editable, setEditable] = useState(false);
  const {
    pages,
    currentPage,
    setFocus,
    handleKeyDowns,
    handleKeyUps,
    updateNode,
    save,
    isEditable,
    setFocusCords,
    setShowMenuButtons,
    handlePaste,
  } = useDBStore((state) => state);
  const { nodes, focusedNode } = pages[currentPage];
  const node = nodes[id];

  const handleInput = () => {
    const selObj = rangy.getSelection();
    const _selection = rangy.serializeSelection(selObj, true, inputRef.current);
    setSelection(_selection);
    save({
      node: {
        ...node,
        text: inputRef.current.innerHTML,
        selection: _selection,
      },
      focusedId: id,
    });
    // setHtml(inputRef.current.innerHTML);
  };

  const handleFocus = (e) => {
    const target = e.currentTarget.closest(".nodeWrapper");
    const cords = getAbsolutePosition(target);
    setFocusCords(cords);
    setShowMenuButtons(true); // For Animation purposes only
  }

  useEffect(() => {
    if (inputRef.current && focusedNode === id) {
      const $this = inputRef.current;
      $this.focus();
      setHtml(node.text);
      setSelection(node.selection);
      try {
        rangy.deserializeSelection(node.selection, inputRef.current);
      } catch (error) {
        // console.log(error);
      }
    }
  }, [node.text, html]);

  return (
    <span
      ref={inputRef}
      contentEditable={isEditable}
      onInput={handleInput}
      onMouseUp={() => setEditable(false)}
      // onFocus={handleFocus}
      onKeyDown={handleKeyDowns}
      onPaste={handlePaste}
      onKeyUp={handleKeyUps}
      onMouseLeave={(e) =>
        updateNode(id, {
          text: e.currentTarget.innerHTML,
          selection: selection,
        })
      }
      dangerouslySetInnerHTML={{ __html: node.text }}
      {...otherProps}
    ></span>
  );
};

export default ContentEditable;
