import { useDBStore } from "../../stores/dbStore";
import ContentEditable from "../content/ContentEditable";

const Callout = ({ id, renderer }) => {
  const { pages, currentPage, setDestination } = useDBStore((state) => state);
  const { nodes } = pages[currentPage];
  const node = nodes[id];

  const handleDragOver = ({ target }) => {
    // const target = e.target.querySelector('[contenteditable]');
    // const id = target?.getAttribute("data-id");
    document.querySelector(".activeBlock")?.classList.remove("activeBlock");
    target?.classList.remove("n");
    target?.classList.add("n");
    setDestination(id);
  };

  const handleDragLeave = () => {
    const target = document.querySelector(".n");
    target?.classList.remove("n");
    // setDestination(null);
  };


  return (
    <div
      key={id}
      className="flex-col w-full pl-6 pb-3 pr-3 bg-[#F1F1EF] rounded-lg"
    >
      {/* <div className="w-[40px] h-full mr-3">
        <IoBulbOutline className="size-[20px]" />
      </div> */}
      <div className="size-full"
      >
        <ContentEditable
          data-id={id}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          key={id}
          id={id}
          className="outline-none text-normal mt-0.5 w-full min-h-5 inline-block"
          aria-placeholder="Type Something..."
        />
      </div>
      <div key={id + "2"} className={`flex-col ${node.children?.length > 0 ? 'flex' : 'hidden'}`}>
        {node.children?.map((childId) => {
          return renderer(childId, nodes[childId].type);
        })}
      </div>
    </div>
  );
};

export default Callout;
