import React, { useEffect } from "react";
import MenuComponent from "./Menu";
import InlineToolbar from "./InlineToolbar";
import { RenderAllNodes } from "../utils";
import { useDBStore } from "../stores/dbStore";
import SubMenuComponent from "./SubMenu";
import { findNodeById, getAbsolutePosition } from "../helpers";
import { rangy } from "../DOM";
import Mention from "./plugins/Mention";

const Editor = () => {
  const {
    setShowInlineTool,
    doRedo,
    doUndo,
    setUndo,
    setInlineToolCords,
    currentPage,
    showInlineTool,
    setShowMention,
    setShowSubMenu,
    createNewBlock,
    setActiveNode,
    setFocus,
    pages,
    initDB
  } = useDBStore((state) => state);
  const { activeNode, nodes, blocks } = pages[currentPage];
  const node = nodes[activeNode];

  const handleKeyDown = (e) => {
    const key = e.key;
    const ctrl = e.ctrlKey || e.metaKey;
    const shift = e.shiftKey;
    const selection = window.getSelection();
    const range = selection?.rangeCount > 0 ? selection.getRangeAt(0) : null;
    switch (key.toLowerCase()) {
      case "z":
        if (ctrl && !shift) {
          // console.log("Undo");
          e.preventDefault();
          doUndo();
          return;
        }
        if (ctrl && shift) {
          e.preventDefault();
          doRedo();
          return;
        }
        break;

      case "Escape":
        e.preventDefault();
        setShowMention(null);
        setShowSubMenu(null);
        break;
      case "Backspace":
        const _range = document.createRange();
        const editor = document.querySelector("#editor");
        editor.classList.contains("selected") && e.preventDefault();
        _range.selectNodeContents();
        console.log("Deleting All...");
        if (range.toString() === _range.toString()) {
          initDB();
        }
        break;
      case "a":
        if (ctrl && !shift && range) {
          const container = range.commonAncestorContainer.parentElement.closest('.nodeWrapper');
          const editable = container.querySelector("[contenteditable]");
          if (container && editable) {
            if (editable?.text === "") {
              e.preventDefault();
              const editor = document.querySelector("#editor");
              selection.removeAllRanges();
              selection.selectAllChildren(editor);
            }
            else {
              const _range = document.createRange();
              _range.selectNodeContents(editable);
              const selectAll = range.toString() === _range.toString();
              // console.log(selectAll);
              if (selectAll) {
                e.preventDefault();
                const editor = document.querySelector("#editor");
                selection.removeAllRanges();
                selection.selectAllChildren(editor);
                editor.classList.add("selected")
              }

            }
          }
        }
        break
      default:
        break;
    }
  };

  const mouseUpFn = () => {
    if (showInlineTool) {
      const activeElement = document
        .querySelector(`.nodeWrapper[data-id="${activeNode}"]`)
        ?.querySelector("[contenteditable]");

      const selObj = rangy.getSelection();
      const _selection = activeElement
        ? rangy.serializeSelection(selObj, true, activeElement)
        : null;
      const data = {
        node: {
          ...node,
          text: activeElement?.innerHTML,
          selection: _selection,
        },
        focusedId: activeNode,
      };
      activeElement && setUndo(data);
    }
  };

  const handleSelection = () => {
    const selection = window.getSelection();
    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    const container = range?.commonAncestorContainer.parentElement;
    const editor = container?.closest('#editor')
    if (selection.type === "Range" && editor) {
      const cords = getAbsolutePosition(range);
      setInlineToolCords(cords);
      setShowInlineTool(true);
      // setEditable(false);
      document.addEventListener("mouseup", mouseUpFn, { once: true });
      // editor.setAttribute('contenteditable', true);
      return;
    }
    // document.querySelector("#editor").removeAttribute('contenteditable');
    // setEditable(true);
    document.querySelector("#editor")?.classList.remove("selected");
    document.removeEventListener("mouseup", mouseUpFn, { once: true });
    !container?.closest(".inline-tool") && setShowInlineTool(null);
  };


  const handleClick = ({ target }) => {
    const lastHblockId = target.previousElementSibling?.getAttribute('data-id');
    const hBlock = nodes[lastHblockId];
    if (hBlock.children?.length === 1) {
      const child = nodes[hBlock.children[0]];
      if (child.children?.length === 1) {
        const gChild = nodes[child.children[0]];
        const gChildElement = findNodeById(gChild.id, target.previousElementSibling?.previousElementSibling);
        if (gChild.type === 'paragraph' && gChildElement?.textContent === '') {
          // Focus on it
          setFocus(null);
          setActiveNode(null); // For rerendering purposes
          setFocus(gChild.id);
          setActiveNode(gChild.id);
          gChildElement?.focus();
          return;
        }
      }
    }
    createNewBlock("paragraph", blocks.length)
  }
  useEffect(() => {
    document.addEventListener("selectionchange", handleSelection, true);
    document.addEventListener("keydown", handleKeyDown, true);
  }, []);
  return (
    <div className="h-full">
      <div
        id="editor"
        className="h-full"
      >
        <RenderAllNodes />
        <div className="w-full h-16" onClick={handleClick}></div>
      </div>
      <Mention />
      <MenuComponent />
      <SubMenuComponent />
      <InlineToolbar />
    </div>
  );
};

export default Editor;
