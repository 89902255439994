import { useState } from "react";
import { Checkbox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/16/solid";
import { useDBStore } from "../../stores/dbStore";
import ContentEditable from "../content/ContentEditable";

const Title = ({ id, checked }) => {
  return (
    <ContentEditable
      id={id}
      style={{ textDecoration: checked ? "line-through" : "none" }}
      className="outline-none text-normal p-1.5 mb-2 min-w-full inline-block"
      aria-placeholder="Todo"
    />
  );
};

const Todo = ({ id, renderer }) => {
  const { pages, currentPage } = useDBStore((state) => state);
  const { nodes } = pages[currentPage];
  const node = nodes[id];
  const [enabled, setEnabled] = useState(false);
  return (
    <div className="w-full flex">
      <Checkbox
        checked={enabled}
        onChange={setEnabled}
        className="group size-5 rounded-md bg-white/10 p-1 mt-2 ring-1 ring-gray-500/50 ring-inset data-[checked]:bg-gray-500/50"
      >
        <CheckIcon className="opacity-0 size-3 fill-white group-data-[checked]:opacity-100" />
      </Checkbox>

      <div className="flex flex-col w-full ml-2">
        <Title id={id} checked={enabled} />
        {node.children.map((childId) => {
          return renderer(childId, nodes[childId].type);
        })}
      </div>
    </div>
  );
};

export default Todo;
