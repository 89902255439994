import React, { useEffect, useRef } from "react";
import { useDBStore } from "../../stores/dbStore";
import { getAbsolutePosition } from "../../helpers";

const Wrapper = ({ id, children }) => {
  const {
    setHover,
    setFocusCords,
    setActiveNode,
    showMenu,
    setShowMenuButtons,
    setFocus,
    pages,
    currentPage,
    draggedNode,
    setDestination
  } = useDBStore((state) => state);
  const { nodes, focusedNode } = pages[currentPage];
  const ele = useRef("");
  const node = nodes[id];

  const handleMouseOver = (e) => {
    if (!showMenu) {
      const target = e.target.closest(".nodeWrapper[data-id]");
      const targetId = target?.getAttribute("data-id");
      setHover(targetId);
      setActiveNode(targetId);
      const cords = getAbsolutePosition(target);
      setFocusCords(cords);
      setShowMenuButtons(false);
      setTimeout(() => {
        setShowMenuButtons(true); // For Animation purposes only
      }, 100);
    }
  };

  // const handleMouseLeave = (e) => {
  //   if (!showMenu) {
  //     setHover(null);
  //   }
  // };

  const handleDragOver = (e) => {

    const wrapper = e.target.closest(".nodeWrapper");
    const draggedWrapper = document.querySelector(
      ".nodeWrapper[data-id='" + draggedNode + "']"
    );
    const _id = wrapper?.getAttribute("data-id");
    const vBlockId = wrapper?.closest(".v-block").getAttribute("data-id");
    const nodeHParentId = nodes[vBlockId].parent;
    const nodeParentId = nodes[_id].parent;
    const nodeParent = nodes[nodeParentId];
    const nodeVParent = nodes[vBlockId];
    const nodeHParent = nodes[nodeHParentId];
    const onlyHBlockChild = nodeHParent.children.length === 1;
    const onlyVBlockChild = nodeVParent.children.length === 1;
    const onlyParentChild = nodeParent.children.length === 1;
    const isBlock = onlyHBlockChild && onlyVBlockChild && onlyParentChild && nodeParentId === vBlockId

    if (
      isBlock ||
      id === draggedNode ||
      draggedWrapper?.querySelector(".activeWrapper")
    ) {
      // Make sure it is not within itself
      document.querySelector(".activeBlock")?.classList.remove("activeBlock");
      document
        .querySelector(".activeWrapper")
        ?.classList.remove("activeWrapper");
      return;
    }
    wrapper?.classList.add("activeWrapper");
    setDestination(_id);
  };

  const handleDragLeave = () => {
    const target = document.querySelector(".activeWrapper");
    target?.classList.remove("activeWrapper");
    document.querySelector(".activeBlock")?.classList.remove("activeBlock");
    // setDestination(null);
  };


  useEffect(() => {
    // const handleFocusIn = (e) => {
    //   // setHover(id);
    //   setActiveNode(id);
    // };

    // const handleFocusOut = (e) => {
    //   updateNode(id, { text: e.currentTarget.innerHTML });
    // };

    const wrapper = ele.current;
    const editable = wrapper.querySelector("[contenteditable]");
    editable?.addEventListener("focusin", () => {
      setActiveNode(id);
      setFocus(id);
    });

    // editable?.addEventListener("focusOut", () => {
    //   setHover(null);
    //   setActiveNode(null);
    // });
  });

  return (
    <div
      ref={ele}
      data-id={id}
      style={{ ...node?.styles, backgroundColor: focusedNode === id ? "#F3F3F3" : node.styles?.backgroundColor }}
      className="nodeWrapper w-full px-0.5 rounded-sm flex flex-col justify-center"
      onMouseOver={handleMouseOver}
      // onMouseEnter={handleMouseOver}
      onMouseDown={handleMouseOver}
      // onMouseLeave={handleMouseLeave}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      {children}
    </div>

  );
};

export default Wrapper;
