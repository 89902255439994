import { useDBStore } from "../../stores/dbStore";
import ContentEditable from "../content/ContentEditable";
import { RxDotFilled } from "react-icons/rx";


export function UList({ id, renderer }) {
  const { pages, currentPage } = useDBStore((state) => state);
  const { nodes } = pages[currentPage];
  const node = nodes[id];
  const children = node.children;
  return (
    <>
      <div className="flex w-full">
       <span className="pt-1.5">
       <RxDotFilled />
       </span>
        <ContentEditable
          id={id}
          className="outline-none text-normal px-1 my-0.5 min-w-full min-h-5 inline-block"
          aria-placeholder="List"
        />
      </div>
      <div className="pl-2 w-full">
        {children.map((childId) => {
          return renderer(childId, nodes[childId].type);
        })}
      </div>
    </>
  )
}




export function OList({ id, renderer }) {
  const { pages, currentPage } = useDBStore((state) => state);
  const { nodes } = pages[currentPage];
  const node = nodes[id];
  const children = node.children;
  return (
    <>
      <div className="flex w-full">
        <span>
          O
        </span>
        <ContentEditable
          id={id}
          className="outline-none text-normal px-1.5 my-0.5 min-w-full min-h-5 inline-block"
          aria-placeholder="List"
        />
      </div>
      <div className="pl-4 w-full">
        {children.map((childId) => {
          return renderer(childId, nodes[childId].type);
        })}
      </div>
    </>
  )
}