import { useLink } from "../../hooks";
import { useDBStore } from "../../stores/dbStore";

const Breadcrumb = () => {
  const { history, pages } = useDBStore((state) => state);
  const { navigateTo } = useLink();

  const pageData = (id) => {
    const page = pages[id];
    let title = page?.title;
    title = title ?? "Untitled";
    const elipsis = title.length > 20 ? "..." : "";
    return { elipsis, title, page }
  }

  return (
    <div className="flex w-[80%] align-middle *:px-1 *:my-[auto]">

      {history.length > 3 &&
        (() => {
          const id = history[0];
          const { elipsis, title, page } = pageData(id);
          return (
            <>
              <div>
                <button
                  key={id}
                  onClick={() => navigateTo(id)}
                  className="font-normal p-2  text-slate-700 rounded-lg hover:bg-slate-100 hover:text-slate-900"
                >
                  {`${page?.icon ?? ""}  ${title.substr(0, 20) + elipsis}`}
                </button>
                {"   /   "}
              </div>
              <div p-2>{"   [...]    /"}</div>
            </>
          )
        })()
      }
      {history.slice(-3).map((id) => {
        const { elipsis, title, page } = pageData(id);
        return (
          <div>
            <button
              key={id}
              onClick={() => navigateTo(id)}
              className="font-normal p-2  text-slate-700 rounded-lg hover:bg-slate-100 hover:text-slate-900"
            >
              {`${page?.icon ?? ""}  ${title.substr(0, 20) + elipsis}`}
            </button>
            {"   /   "}
          </div>
        );
      })}

    </div>
  );
};

export default Breadcrumb;