import { useDBStore } from "../../stores/dbStore";
import ContentEditable from "../content/ContentEditable";

const Quote = ({ id, renderer }) => {
  const { pages, currentPage } = useDBStore((state) => state);
  const { nodes } = pages[currentPage];
  const node = nodes[id];

  return (
    <div className="flex flex-col border-l-2 border-l-gray-600">
      <ContentEditable
        key={id}
        id={id}
        className="outline-none text-normal p-1.5 min-w-full inline-block"
        aria-placeholder="Type here..."
      />
      {node.children.length > 0 &&
        node.children.map((childId) => {
          return renderer(childId, nodes[childId].type);
        })}
    </div>
  );
};

export default Quote;
