import { useDBStore } from "./stores/dbStore";
import { Elements } from "./constants";
import { Fragment, memo, useEffect } from "react";

const renderNode = (id, type) => {
  const Element = Elements[type](id, renderNode);
  return Element;
};
export const RenderAllNodes = memo(() => {
  const { createNewBlock, pages, currentPage } = useDBStore((state) => state);
  const { nodes, blocks } = pages[currentPage];

  // useEffect(() => {
  //   // blocks.length === 0 && createNewBlock();
  // }, [blocks]);
  return (
    <>
      {blocks?.length > 0 && 
      blocks.map((blockId, i) => {
        const block = nodes[blockId];
        return (
          <Fragment key={blockId}>{renderNode(block.id, block.type)}</Fragment>
        );
      })}
    </>
  );
});
